<template>
    <div class="p-[16px_20px] border-b-[1px] border-b-[#E2E4E9]">
        <InputField v-model="listName" placeholder="Name" />
    </div>
    <div class="p-[16px_20px] flex gap-[12px]">
        <button class="button-secondary w-full" @click="onCancel">Cancel</button>
        <button class="button-primary w-full" @click="onCreate">Save Changes</button>
    </div>
</template>
<script>
import useListStore from '@/stores/List';
import InputField from '@/components/inputs/InputField.vue';

export default {
    setup: () => ({
        store: useListStore()
    }),
    props: {
        interface: {
            type: Object,
            required: true
        },
        params: {
            type: Object,
            default: null
        }
    },
    components: { InputField },
    data: () => ({
        listName: ''
    }),
    mounted() {
        this.interface.modal.setHeader('New List', 'list');
    },
    methods: {
        close() {
            CURRENT_PAGE == 'lists#index'
                ? this.interface.modal.close()
                : this.interface.modal.setView('ModalListProfileAdd');
        },
        onCancel() {
            this.close();
        },
        async onCreate() {
            const data = { name: this.listName };
            await this.store.createList(data);
            this.interface.notify('The list was created successfully.');
            this.close();
        }
    }
}
</script>